import Decimal from 'decimal.js'
import { numberWithCommas } from 'rfx/lib/numbers'

export const NEXT_PUBLIC_BIRDEYE_API_KEY =
  process.env.NEXT_PUBLIC_BIRDEYE_API_KEY ||
  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2NzM0NTE4MDF9.KTEqB1hrmZTMzk19rZNx9aesh2bIHj98Cb8sg5Ikz-Y'

export const API_URL = 'https://public-api.birdeye.so/'

export const socketUrl = `wss://public-api.birdeye.so/socket?x-api-key=${NEXT_PUBLIC_BIRDEYE_API_KEY}`

// Make requests to Birdeye API
export async function makeApiRequest(path: string) {
  const response = await fetch(`${API_URL}${path}`, {
    headers: {
      'X-API-KEY': NEXT_PUBLIC_BIRDEYE_API_KEY,
    },
  })
  return response.json()
}

export const RESOLUTION_MAPPING: Record<string, string> = {
  '1': '1m',
  '3': '3m',
  '5': '5m',
  '15': '15m',
  '30': '30m',
  '60': '1H',
  '120': '2H',
  '240': '4H',
  '1D': '1D',
  '1W': '1W',
}

export function parseResolution(resolution: string) {
  if (!resolution || !RESOLUTION_MAPPING[resolution]) {
    return RESOLUTION_MAPPING[0]
  }

  return RESOLUTION_MAPPING[resolution]
}

export const SUBSCRIPT_NUMBER_MAP: Record<number, string> = {
  4: '₄',
  5: '₅',
  6: '₆',
  7: '₇',
  8: '₈',
  9: '₉',
  10: '₁₀',
  11: '₁₁',
  12: '₁₂',
  13: '₁₃',
  14: '₁₄',
  15: '₁₅',
}

export const calcPricePrecision = (num: number | string) => {
  if (!num) {
    return 8
  }

  switch (true) {
    case Math.abs(+num) < 0.00000000001:
      return 16

    case Math.abs(+num) < 0.000000001:
      return 14

    case Math.abs(+num) < 0.0000001:
      return 12

    case Math.abs(+num) < 0.00001:
      return 8

    case Math.abs(+num) < 0.05:
      return 6

    case Math.abs(+num) < 1:
      return 4

    case Math.abs(+num) < 20:
      return 3

    default:
      return 2
  }
}

export const formatPrice = (
  num: number,
  precision?: number,
  gr0 = true,
  addCommas = true,
): string => {
  if (!num) {
    return num.toString()
  }

  if (!precision) {
    precision = calcPricePrecision(+num)
  }

  let formated: string = addCommas
    ? numberWithCommas(new Decimal(num).toFixed(precision || 2))
    : new Decimal(num).toFixed(precision || 2)

  if (formated.match(/^0\.[0]+$/g)) {
    formated = formated.replace(/\.[0]+$/g, '')
  }

  if (gr0 && formated.match(/\.0{4,15}[1-9]+/g)) {
    const match = formated.match(/\.0{4,15}/g)
    if (!match) {
      return ''
    }
    const matchString: string = match[0].slice(1)
    formated = formated.replace(
      /\.0{4,15}/g,
      `.0${SUBSCRIPT_NUMBER_MAP[matchString.length]}`,
    )
  }

  return formated
}
