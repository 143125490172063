import Token from 'abis/Token.json'
import ExternalLink from 'components/ExternalLink/ExternalLink'
import { getExplorerUrl } from 'config/chains'
import { IS_VERBOSE } from 'config/development'
import { Signer, ethers } from 'ethers'
import { helperToast } from 'rfx/lib/helperToast'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'
import { InfoTokens, TokenInfo } from './types'
import { encodeFunctionData } from 'viem'

type Params = {
  setIsApproving: (val: boolean) => void
  signer: Signer | undefined
  tokenAddress: string
  spender: string
  chainId: number
  onApproveSubmitted: () => void
  getTokenInfo?: (infoTokens: InfoTokens, tokenAddress: string) => TokenInfo
  infoTokens: InfoTokens
  pendingTxns: PendingTransaction[]
  setPendingTxns: (txns: PendingTransaction[]) => void
  sendPaymasterTransaction: SendPaymasterTransactionFn
  account: string
  includeMessage?: boolean
}

export function approveTokens({
  setIsApproving,
  signer,
  tokenAddress,
  spender,
  chainId,
  onApproveSubmitted,
  getTokenInfo,
  infoTokens,
  pendingTxns,
  setPendingTxns,
  includeMessage,
  sendPaymasterTransaction,
  account,
}: Params) {
  setIsApproving(true)

  const contract = new ethers.Contract(tokenAddress, Token.abi, signer)
  const calldata = encodeFunctionData({
    abi: Token.abi,
    args: [spender, ethers.constants.MaxUint256],
    functionName: 'approve',
  })

  const call: Parameters<typeof sendPaymasterTransaction>[0]['call'] = {
    address: tokenAddress,
    calldata,
  }

  const messageOpts = {
    hideSentMsg: true,
    hideSuccessMsg: true,
  }

  sendPaymasterTransaction({
    call,
    account: account,
    messageOpts,
    router: contract,
    payload: [spender, ethers.constants.MaxUint256],
    method: 'multicall',
  })
    .then(async (res: any) => {
      const txUrl = getExplorerUrl(chainId) + 'tx/' + res
      helperToast.success(
        <div>
          <div className="flex flex-col gap-1 text-xs font-semibold">
            <span>Approval submitted! </span>
            <ExternalLink className="text-th-active" href={txUrl}>
              View status.
            </ExternalLink>
          </div>
          <br />
        </div>,
      )
      if (onApproveSubmitted) {
        onApproveSubmitted()
      }

      if (getTokenInfo && infoTokens && pendingTxns && setPendingTxns) {
        const token = getTokenInfo(infoTokens, tokenAddress)
        const pendingTxn = {
          hash: res as string,
          message: includeMessage ? `${token.symbol} Approved!` : '',
        }
        setPendingTxns([...pendingTxns, pendingTxn])
      }
    })
    .catch((e: any) => {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error(e)
      let failMsg
      if (
        [
          'not enough funds for gas',
          'failed to execute call with revert code InsufficientGasFunds',
        ].includes(e.data?.message)
      ) {
        failMsg = (
          <div>
            There is not enough ETH in your account on Arbitrum to send this
            transaction.
            <br />
            <br />
            <ExternalLink href="https://arbitrum.io/bridge-tutorial/">
              Bridge ETH to Arbitrum
            </ExternalLink>
          </div>
        )
      } else if (e.message?.includes('User denied transaction signature')) {
        failMsg = `Approval was cancelled`
      } else {
        failMsg = `Approval failed`
      }
      helperToast.error(failMsg)
    })
    .finally(() => {
      setIsApproving(false)
    })
}
