import ExternalLink from 'components/ExternalLink/ExternalLink'
import { getExplorerUrl } from 'config/chains'
import { ethers } from 'ethers'
import { helperToast } from 'rfx/lib/helperToast'

const notifications: { [id: string]: boolean } = {}

export function pushSuccessNotification(
  chainId: number,
  message: string,
  e: { transactionHash: string },
) {
  const { transactionHash } = e

  const id = ethers.utils.id(message + transactionHash)
  if (notifications[id]) {
    return
  }

  notifications[id] = true

  const txUrl = getExplorerUrl(chainId) + 'tx/' + transactionHash
  helperToast.success(
    <div className="flex flex-col gap-1 text-xs font-semibold">
      <span>{message}</span>
      <ExternalLink className="text-th-active" href={txUrl}>
        View
      </ExternalLink>
    </div>,
  )
}

export function pushErrorNotification(
  chainId: number,
  message: string,
  e: { transactionHash: string },
) {
  const { transactionHash } = e
  const id = ethers.utils.id(message + transactionHash)
  if (notifications[id]) {
    return
  }

  notifications[id] = true

  const txUrl = getExplorerUrl(chainId) + 'tx/' + transactionHash
  helperToast.error(
    <div>
      {message} <ExternalLink href={txUrl}>View</ExternalLink>
    </div>,
  )
}
