import { ethers } from 'ethers'
import { Config, useConnectorClient } from 'wagmi'
import { useMemo } from 'react'
import type { Account, Chain, Client, Transport } from 'viem'
import { UncheckedJsonRpcSigner } from 'utils/rpc/UncheckedJsonRpcSigner'

export function clientToSigner(client: Client<Transport, Chain, Account>) {
  const { account, chain, transport } = client
  const network = {
    chainId: chain?.id,
    name: chain?.name,
    ensAddress: chain?.contracts?.ensRegistry?.address,
  }
  const provider = new ethers.providers.Web3Provider(transport, network)
  const signer = new UncheckedJsonRpcSigner(provider, account.address)
  return signer?.signer
}

/** Hook to convert a Viem Client to an ethers.js Signer. */
export function useEthersSigner({ chainId }: { chainId?: number } = {}) {
  const { data: client } = useConnectorClient<Config>({ chainId })

  return useMemo(() => {
    if (!client) {
      return undefined
    }

    return clientToSigner(client)
  }, [client])
}
