import {
  TransactionRequest,
  TransactionResponse,
} from '@ethersproject/providers'
import { BigNumber } from 'ethers'
import { getRealChainId } from './getRealChainId'

export class UncheckedJsonRpcSigner {
  signer: any
  provider: any
  sendUncheckedTransaction: any
  constructor(provider: any, address: string) {
    this.signer = provider.getSigner(address)

    // Pass the internal parameters to the superclass constructor
    // super(signer._address, signer._provider);
  }

  async estimateGas(tx: TransactionRequest): Promise<BigNumber> {
    await this.assertNetwork()

    return this.provider.getSigner().estimateGas(tx)
  }

  async sendTransaction(
    transaction: TransactionRequest,
  ): Promise<TransactionResponse> {
    await this.assertNetwork()

    return this.sendUncheckedTransaction(transaction).then((hash: any) => {
      return {
        hash,
        nonce: null,
        gasLimit: null,
        gasPrice: null,
        data: null,
        value: null,
        chainId: null,
        confirmations: 0,
        from: null,
        wait: (confirmations?: number) => {
          return this.provider.waitForTransaction(hash, confirmations)
        },
      } as unknown as TransactionResponse
    })
  }

  private async assertNetwork() {
    // Sometimes getNetwork call asserts the network itself, but its metamask, so we need to check it again
    const assumedChainId = Number((await this.provider.getNetwork()).chainId)
    const realChainId = getRealChainId()

    if (realChainId !== undefined && assumedChainId !== realChainId) {
      throw new Error(
        `Invalid network: wallet is connected to ${realChainId}, but the app is on ${assumedChainId}`,
      )
    }
  }
}
